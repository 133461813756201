import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IconsModule,
  ButtonsModule,
  WavesModule,
  CardsModule,
  CarouselModule,
  ModalModule,
} from 'angular-bootstrap-md';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    IconsModule,
    WavesModule,
    FormsModule,
    CardsModule,
    ModalModule,
    ButtonsModule,
    CarouselModule,
    ReactiveFormsModule,
    YouTubePlayerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class KamykaMaterialsModule {}
