<div class="w-100 position-relative">
  <div class="w-100 position-fixed top-0 header-container">
    <app-header></app-header>
    <div
      class="sub-header-container position-fixed bg-primary h-75 d-flex flex-column justify-content-center"
    >
      <div
        class="icon-wrap text-white m-1"
        (click)="coachingNavigation(menu.url)"
        [ngClass]="{
          'about-active': menu.url === active,
          'home-active': menu.url === active,
          'contact-active': menu.url === active
        }"
        *ngFor="let menu of headersMenus"
        [routerLink]="menu.url"
      >
        <mdb-icon fas icon="{{ menu.icon.name }}"></mdb-icon>
      </div>
    </div>
  </div>
  <div class="w-100">
    <router-outlet></router-outlet>
  </div>
  <div class="black-bg footer-container">
    <app-footer></app-footer>
  </div>
</div>
