<div class="w-100 position-relative">
  <section class="top-container-full">
    <mdb-carousel class="h-100" [isControls]="true" [animation]="'fade'">
      <mdb-carousel-item
        class="h-100 w-100 bg-config position-relative"
        [ngStyle]="{ 'background-image': 'url(' + mainSlide.icon.name + ')' }"
        *ngFor="let mainSlide of mainSliderContents"
      >
        <a
          class="w-100 text-white position-absolute absolute-centralizer text-center"
        >
          <h2 class="normal-big top-title">
            {{ mainSlide.label | uppercase }}
          </h2>
          <p class="k-subtitle">{{ mainSlide.friendly | uppercase }}</p>
        </a>
        <div
          class="w-100 d-flex align-items-center justify-content-center position-absolute k-social-media"
        >
          <p
            class="k-pointer text-primary"
            *ngFor="let info of topSocialNetworks"
          >
            <mdb-icon
              class="mr-2 icon-size home-icon"
              fab
              [icon]="info.icon"
            ></mdb-icon>
          </p>
        </div>
      </mdb-carousel-item>
    </mdb-carousel>
  </section>
  <section class="w-100">
    <div class="w-75 mx-auto res-wrapper">
      <div class="w-100 d-flex res-inner-wrap">
        <div
          class="text-white p-5 mb-5 res-content-wrap"
          [ngStyle]="serviceCard.styleConfig"
          *ngFor="let serviceCard of services"
        >
          <h3 class="pb-3 res-title">{{ serviceCard.title | titlecase }}</h3>
          <p class="w-100 default-font res-text">
            {{ serviceCard.description }}
          </p>
          <button
            class="font-weight-bold"
            type="button"
            mdbBtn
            color="light"
            mdbWavesEffect
          >
            {{ serviceCard.actionLabel }}
          </button>
        </div>
      </div>
      <div
        class="w-100 card-mobile mt-5 mb-5 d-flex flex-wrap justify-content-between"
      >
        <mdb-card
          class="service-wrap"
          *ngFor="let service of servicesAvailable"
        >
          <!--Card image-->
          <mdb-card-img [src]="service.img" alt="service image"> </mdb-card-img>
          <!--Card content-->
          <mdb-card-body>
            <div class="w-100 service-tag text-reg-size">
              {{ service.tag | uppercase }} <span class=""></span>
            </div>
            <!--Title-->
            <mdb-card-title class="text-primary">
              <h4>{{ service.title }}</h4>
            </mdb-card-title>

            <!--Text-->
            <mdb-card-text>
              <span class="k-black">{{ service.description }}</span>
            </mdb-card-text>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
  </section>
  <section class="w-100 mt-5 quote-container position-relative">
    <img src="../../../../../assets/img/keynotes.png" />
    <div class="w-50 position-absolute quote-wrapper">
      <mdb-icon
        class="w-100 text-primary normal-big text-center pb-3"
        fas
        icon="quote-left"
      ></mdb-icon>
      <p class="mb-5 pb-5">
        As a coach you’re a consultant, motivational speaker, a change agent,
        and accountability partner that works with managers, entrepreneurs, and
        others to help them define and achieve their goals--- career, personal,
        or most often, both. My mission has been to make quality life coaching
        affordable and available to anyone who strives to live their best life.
      </p>
      <h1 class="last-signature normal-big text-center">
        {{ "Dr. kaMyka Glenn..." }}
      </h1>
    </div>
  </section>
  <section class="w-100">
    <div
      class="w-75 res-reputation bg-primary p-5 d-flex justify-content-between position-relative"
    >
      <div class="w-50 res-full">
        <div class="w-100 pt-5 no-top-pad">
          <div
            class="w-100 text-black no-top-pad pt-5 font-weight-bold title k-black"
          >
            {{ "Working together" }} <br />
            <span class="text-white font-weight-light">{{
              "for your better life"
            }}</span>
          </div>
          <p class="w-100 text-white pt-4 pb-5 reg-font">
            An article revealed a study where training alone was compared to
            life coaching combined with training. The study showed that training
            combined with weekly life coaching increased productivity.
          </p>
          <div class="mb-5">
            <a
              class="font-weight-bold"
              type="button"
              mdbBtn
              color="light"
              [routerLink]="'/testimonials'"
              mdbWavesEffect
            >
              {{ "client testimonials" | uppercase }}
            </a>
            <div
              class="text-white"
              type="button"
              mdbBtn
              color="light"
              outline="true"
              [routerLink]="'/about-us'"
              mdbWavesEffect
            >
              {{ "about life coaching" | uppercase }}
            </div>
          </div>
        </div>
      </div>
      <div class="quote-carosel position-absolute res-testimonials">
        <mdb-carousel [isControls]="false" [animation]="'slide'">
          <mdb-carousel-item class="" *ngFor="let comment of peopleComments">
            <div class="w-100">
              <div class="image-slider">
                <img class="d-block w-100" [src]="comment.image" />
              </div>
              <div class="w-100 text-white p-5 slide-mobile reg-font">
                <mdb-icon
                  class="small-quote pr-2"
                  fas
                  icon="quote-left"
                ></mdb-icon>
                <p>
                  {{ comment.details }}
                </p>
                <span class="w-100 d-block p-3 text-right font-weight-bold">{{
                  comment.signature | uppercase
                }}</span>
              </div>
            </div>
          </mdb-carousel-item>
        </mdb-carousel>
      </div>
    </div>
  </section>
  <section class="w-100 p-5 black no-pad">
    <div class="w-100 d-flex p-5 res-book">
      <div class="img-wrap">
        <img src="../../../../../assets/img/lookingBack.png" />
      </div>
      <div class="w-100 pl-5 res-book-sec">
        <h1 class="m-0 text-white-50">
          {{ "read my books & start" | titlecase }} <br />
          A New Life <br />
          <span class="w-100 text-reg-size"
            >books by
            <span class="icon-size last-signature">Dr. kaMyka Glenn</span></span
          >
        </h1>
        <div class="d-flex pt-5 flex-wrap">
          <div
            class="w-25 pt-5 pl-5 pr-5 pb-2 book-card"
            *ngFor="let slide of availableBooks"
          >
            <a
              class="book-container w-100 d-flex flex-wrap justify-content-around text-white text-center"
              *ngFor="let book of slide.menus"
              [href]="book.url"
              target="_blank"
            >
              <div class="book-wrap">
                <img [src]="book.icon.name" />
              </div>
              <h2 class="pt-5 w-100 res-book-title">
                {{ book.friendly | uppercase }}
              </h2>
            </a>
          </div>
        </div>
        <div class="w-100 p-5 text-center">
          <a
            [routerLink]="'/book-store'"
            type="button"
            mdbBtn
            color="light"
            mdbWavesEffect
          >
            more products
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="w-100 p-5 book-appoitnment position-relative no-pad">
    <div class="pb-5 w-100 position-absolute get-started">
      <h1
        class="m-0 text-primary normal-big font-weight-bold small-line-height"
      >
        Get started now
      </h1>
      <span class="normal-big light-text">with life coaching</span>
      <p class="w-50 res-full-width">
        Get the motivation, guidance, and support you need to get your life on
        track. You don’t need to struggle on your own anymore!
      </p>
      <a
        type="button"
        mdbBtn
        color="primary"
        mdbWavesEffect
        href="https://transformwithcoachk.as.me/"
        target="_blank"
      >
        book an appoitnment <mdb-icon fas icon="calendar-day"></mdb-icon>
      </a>
    </div>
    <div
      class="w-50 p-5 mx-auto position-absolute contact-card bg-primary black text-white"
    >
      <h2 class="">Transformational Hours</h2>
      <div class="w-100">
        <a
          type="button"
          mdbBtn
          color="light"
          mdbWavesEffect
          class="mt-2"
          href="https://transformwithcoachk.as.me/"
          target="_blank"
        >
          book an appoitnment <mdb-icon fas icon="calendar-day"></mdb-icon>
        </a>
        <div class="w-100 pt-2">
          <h1>Contact details</h1>
          <div class="p-2" *ngFor="let contact of contactDetails">
            <mdb-icon fas icon="{{ contact.icon.name }}"></mdb-icon>
            {{ contact.friendly | titlecase }}
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex">
          <a
            [href]="social.url"
            target="_blank"
            class="mr-2 text-white rounded-circle menu-round align-items-center d-flex justify-content-center"
            *ngFor="let social of socialNetworks"
          >
            <mdb-icon fab [icon]="social.icon.name"></mdb-icon>
          </a>
        </div>
      </div>
    </div>
  </section>
</div>
