import { Component, OnInit } from '@angular/core';
import { sliderItem } from 'src/app/kamyka-shares/KamykaInterfaces';
import {
  MenuItem,
  Sectionized,
} from 'src/app/kamyka-shares/components/footer/footer.component';

interface ServiceCard {
  tag?: string;
  title: string;
  img?: string;
  description: string | string[];
  actionLabel: string;
  styleConfig?: object;
}
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  mainSliderContents: MenuItem[] = [
    {
      icon: {
        name: '../../../../../assets/img/handviewe.jpg',
        type: '',
      },
      label: 'discover',
      friendly: 'living a life with passion',
    },
    {
      icon: {
        name: '../../../../../assets/img/slide02.jpg',
        type: '',
      },
      label: 'uncover',
      friendly: 'secrets of life',
    },
    {
      icon: {
        name: '../../../../../assets/img/super.jpg',
        type: '',
      },
      label: 'improve',
      friendly: 'your being',
    },
  ];
  topSocialNetworks: MenuItem[] = [
    {
      icon: {
        name: 'facebook-f',
        type: 'fab',
      },
      url: '',
      friendly: '',
    },
    {
      icon: {
        name: 'twitter',
        type: 'fab',
      },
      url: '',
      friendly: '',
    },
    {
      icon: {
        name: 'youtube',
        type: 'fab',
      },
      url: '',
      friendly: '',
    },
    {
      icon: {
        name: 'linkedin-in',
        type: 'fab',
      },
      url: '',
      friendly: '',
    },
    {
      icon: {
        name: 'instagram',
        type: 'fab',
      },
      url: '',
      friendly: '',
    },
  ];
  socialNetworks: MenuItem[] = [
    {
      url:
        'https://www.youtube.com/channel/UCCvZvzn8sT9HNr-Uk_D_faw?view_as=subscriber',
      icon: {
        name: 'youtube',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://www.linkedin.com/in/life-coach-k-b44b47120/',
      icon: {
        name: 'linkedin-in',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://www.facebook.com/lifecoachk/',
      icon: {
        name: 'facebook-f',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://twitter.com/4LifeCoachK',
      icon: {
        name: 'twitter',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://www.instagram.com/transformationalcoachk/',
      icon: {
        name: 'instagram',
        type: 'fab',
      },
      friendly: '',
    },
  ];
  services: ServiceCard[] = [
    {
      title: 'Coaching & Consulting',
      description:
        'A consultant and motivator Coach K works with her clients to help them define and achieve their goals.',
      actionLabel: 'read more',
      styleConfig: {
        backgroundColor: '#4285F4',
      },
    },
    {
      title: 'Speaker & Book Signings',
      description:
        'As a speaker and author, Coach K provides content that encourages, inspires, and empowers your school or organization.',
      actionLabel: 'read more',
      styleConfig: {
        zIndex: 2,
        marginTop: '-50px',
        paddingTop: '3rem',
        boxShadow:
          '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3)',
        backgroundColor: '#0d47a1',
      },
    },
    {
      title: 'High Level Training',
      description:
        'As a trainer, Coach K provides learning and development for youth, staff, and organizations that matched perfectly to meet the needs of the audience.',
      actionLabel: 'read more',
      styleConfig: {
        backgroundColor: '#4285F4',
      },
    },
  ];
  servicesAvailable: ServiceCard[] = [
    {
      tag: 'being happy',
      title: 'Enjoy your life',
      img: '../../../../assets/img/onfloor.jpg',
      description:
        'A well-trained coach knows how to use the right words so that you are naturally motivated.',
      actionLabel: 'read more',
    },
    {
      tag: 'being happy',
      title: 'Your potential',
      img: '../../../../assets/img/expert.jpg',
      description:
        'Clients take bigger actions, set bigger goals and think bigger when they work with a life coach.',
      actionLabel: 'read more',
    },
    {
      tag: 'being happy',
      title: 'Finding balance',
      img: '../../../../assets/img/family.jpg',
      description:
        'A coach K. knows how to use the right words so that you are naturally motivated.',
      actionLabel: 'read more',
    },
    {
      tag: 'being happy',
      title: 'Accept change',
      img: '../../../../assets/img/breath.jpg',
      description:
        'Most people find that things start to happen very quickly after hiring a life coach.',
      actionLabel: 'read more',
    },
  ];
  peopleComments: sliderItem[] = [
    {
      image: '../../../assets/testimonials/private.jpeg',
      details:
        'KaMyka is an example of authenticity. She genuinely cares for others and guides them along their path with practical and spiritual',
      signature: 'Sherri Y. Johnson',
    },
    {
      image: '../../../../assets/testimonials/hetlena.jpeg',
      details:
        'KaMyKa is a genius at helping others realize their potentialand power.' +
        'I realy enjoyed having her coach me to a new level in my live',
      signature: 'Hetlena Johnson',
    },
    {
      image: '../../../../assets/testimonials/labron.jpeg',
      details:
        'I cannot believe you were able to guide us through this major' +
        'life decision with just one month of coaching. So, thank you, again for helping us see through the fog!',
      signature: 'LaRon Martin',
    },
  ];

  availableBooks: Sectionized[] = [
    {
      title: '',
      menus: [
        {
          icon: {
            name: '../../../../../../assets/img/wow.jpg',
            type: '',
          },
          url:
            'http://www.lulu.com/shop/kamyka-glenn/wow-voices-of-youth/paperback/product-24097619.html',
          friendly: 'w.o.w',
        },
      ],
    },
    {
      title: '',
      menus: [
        {
          icon: {
            name: '../../../../../../assets/img/cdyouth.jpg',
            type: '',
          },
          url: 'http://store.cdbaby.com/cd/idreamacademypresentsvoicesofy',
          friendly: 'voices of youth',
        },
      ],
    },
    {
      title: '',
      menus: [
        {
          icon: {
            name: '../../../../../../assets/img/dreamchronicle.jpg',
            type: '',
          },
          url:
            'http://www.lulu.com/shop/kamyka-glenn/dream-chronicles/paperback/product-23774731.html',
          friendly: 'dream chronicles',
        },
      ],
    },
  ];

  hoursOfOperations = [
    {
      days: 'Monday - Wednesday',
      time: '8AM - 7pm',
    },
    {
      days: 'Thursday',
      time: '8AM - 5PM',
    },
    {
      days: 'Friday',
      time: '8AM - 6PM',
    },
    {
      days: 'Saturday - Sunday',
      time: 'Closed',
    },
  ];

  contactDetails: MenuItem[] = [
    {
      icon: {
        name: 'mobile',
        type: 'fas',
      },
      friendly: '+1 (410) 417 - 7520',
    },
    {
      icon: {
        name: 'envelope',
        type: 'fas',
      },
      friendly: 'connect@lifecoachk.com',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
