<div class="w-100 book-container position-relative">
  <section class="w-100 text-center title-section pt-5">
    <h1 class="m-0 pt-5">
      Coach K's product <span class="text-primary">library</span>
    </h1>
    <span class="title-bg">read my book</span>
  </section>
  <section
    class="w-100 book-list d-flex justify-content-center flex-wrap bg-white p-5"
  >
    <div *ngFor="let book of books" class="p-5 m-5 border book-wrap">
      <div
        class="image"
        [ngStyle]="{ 'background-image': 'url(' + book.img + ')' }"
      ></div>
      <div class="p-5 text-center font-weight-bold">{{ book.title }}</div>
    </div>
  </section>
  <section
    class="w-50 mx-auto text-light quote-container d-flex flex position-absolute"
  >
    <div class="p-5 bg-primary">
      <p>
        KaMyka is a creative who uses her craft to showcase her abilities hrough
        writing, music and other arts, and speaking in order to impact the lives
        of others.
      </p>
    </div>
  </section>
</div>
