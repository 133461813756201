<div class="event-container pt-5">
  <section class="w-100 text-center title-section">
    <h1 class="m-0">coach K's <span class="text-primary">events</span></h1>
    <span class="title-bg">events</span>
  </section>
  <section class="content-container w-100 p-5 bg-secondary">
    <div class="w-75 mx-auto res-inner-event">
      <div
        class="w-100 d-flex justify-content-center event-wrapper p-5 flex-wrap"
        *ngFor="let event of coachingEvents"
      >
        <div class="event-wrap text-center pb-5">
          <img [src]="event.image" />
        </div>
      </div>
      <div class="w-100 text-white text-center">
        <div>
          <div class="w-100">
            <a
              target="_blank"
              href="https://us02web.zoom.us/webinar/register/WN_09qYKkN_TDq94dClfx8eNQ"
              class="registration-btn"
              type="button"
              mdbBtn
              color="primary"
              mdbWavesEffect
            >
              {{ "discover registration" | uppercase }}
            </a>
          </div>
          <p>
            Thursday, Oct 12, 2023 at 7pm <br />Session 1: DISCOVER <br />
            A live transformative session with Coach K to help you live beyond
            the mask and prevail. <br />Registration $47
          </p>
        </div>
        <div>
          <div class="w-100">
            <a
              target="_blank"
              href="https://us02web.zoom.us/webinar/register/WN_JS3LE2ygTqSj14HRvvNaVQ"
              class="registration-btn"
              type="button"
              mdbBtn
              color="primary"
              mdbWavesEffect
            >
              {{ "activate registration" | uppercase }}
            </a>
          </div>
          <p>
            Friday, Oct 13, 2023 at 7pm <br />Session 2: ACTIVATE <br />THE THE
            MAN CAVE: What are the steps for men to prevail?
          </p>
        </div>
        <div class="w-100">
          <div>
            <a
              target="_blank"
              href="https://us02web.zoom.us/webinar/register/WN_CNrMkZnzQLSIMHjCA12vVA"
              class="registration-btn"
              type="button"
              mdbBtn
              color="primary"
              mdbWavesEffect
            >
              {{ "blaze registration" | uppercase }}
            </a>
          </div>
          <p>
            Saturday, Oct 14, 2023 at 10 am <br />Session 3: BLAZE <br />
            A life-changing session to shift from the mask and prevail.
          </p>
        </div>
      </div>
    </div>
  </section>
</div>
