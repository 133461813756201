<div class="contact-container p-5">
  <section class="w-100 text-center title-section">
    <h1 class="m-0">get in <span class="text-primary">touch</span></h1>
    <span class="title-bg">contact</span>
  </section>
  <section class="w-100 text-white">
    <div class="w-100 d-flex flex-wrap justify-content-around">
      <div class="w-25 p-5 contact-wrap">
        <h3 class="font-weight-bold">DON'T BE SHY !</h3>
        <p>
          Feel free to get in touch with me. I am always open to discussing new
          projects, creative ideas or opportunities to be part of your visions.
        </p>
        <p
          class="mb-3 d-flex align-items-center"
          *ngFor="let info of contactInfos"
        >
          <mdb-icon
            class="text-primary mr-2 icon-size"
            fas
            [icon]="info.icon.name"
          ></mdb-icon>
          <span class="d-block">
            <span class="d-block font-weight-light text-alt opacity">{{
              info.label | uppercase
            }}</span>
            <span class="font-weight-bold">{{ info.friendly }}</span>
          </span>
        </p>
        <div class="d-flex">
          <a
            [href]="social.url"
            target="_blank"
            class="mr-2 text-white rounded-circle menu-round align-items-center d-flex justify-content-center"
            *ngFor="let social of socialNetworks"
          >
            <mdb-icon fab [icon]="social.icon.name"></mdb-icon>
          </a>
        </div>
      </div>
      <div class="w-50 contact-wrap p-5">
        <form [formGroup]="contactForm" (ngSubmit)="submit()" class="w-100">
          <div class="d-flex justify-content-between flex-wrap">
            <input
              type="text"
              class="input-frame pl-4 border-0 form-control"
              placeholder="YOUR NAME"
              formControlName="visitorName"
            />
            <input
              type="text"
              class="input-frame pl-4 border-0"
              placeholder="PHONE NUMBER"
              formControlName="visitorPhoneNumber"
            />
            <input
              type="text"
              class="input-frame pl-4 border-0"
              placeholder="YOUR EMAIL"
              formControlName="visitorEmail"
            />
            <input
              type="text"
              class="input-frame pl-4 border-0"
              placeholder="SUBJECT"
              formControlName="visitorMessageSubject"
            />
          </div>
          <textarea
            type="text"
            class="input-frame w-100 mt-4 p-3 border-0"
            placeholder="YOUR MESSAGE"
            formControlName="visitorMessage"
          ></textarea>
          <div class="w-100 mt-4">
            <button
              (click)="basicModal.show()"
              [disabled]="contactForm.invalid"
              class="input-frame mb-5 text-white bg-primary d-flex justify-content-center align-items-center"
            >
              {{ buttonTxt | uppercase }}
              <mdb-icon
                class="ml-2 animated slideInLeft sending"
                fas
                icon="paper-plane"
              ></mdb-icon>
            </button>
          </div>
        </form>
        <div class="confirmation">
          <div
            mdbModal
            #basicModal="mdbModal"
            class="modal fade"
            tabindex="-1"
            role="dialog"
            position
            aria-labelledby="myBasicModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content bg-primary">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close pull-right"
                    aria-label="Close"
                    (click)="basicModal.hide()"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                  <h4 class="modal-title w-100" id="myModalLabel">
                    {{ acknowledgmentTtile }}
                  </h4>
                </div>
                <div class="modal-body">
                  {{ message }}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    mdbBtn
                    color="light"
                    class="relative waves-light"
                    (click)="basicModal.hide()"
                    mdbWavesEffect
                  >
                    {{ acknowledgment }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
