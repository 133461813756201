import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { KamykaMaterialsModule } from 'src/app/kamyka-materials/kamyka-materials.module';
import { HomepageComponent } from './components/homepage/homepage.component';

@NgModule({
  declarations: [HomepageComponent],
  imports: [CommonModule, KamykaMaterialsModule, HomeRoutingModule],
})
export class HomeModule {}
