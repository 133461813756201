import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { KamykaSharesModule } from './kamyka-shares/kamyka-shares.module';
import { HomeModule } from './Pages/home/home.module';
import { ContactUsModule } from './Pages/contact-us/contact-us.module';
import { AboutUsModule } from './Pages/about-us/about-us.module';
import { BookStoreModule } from './Pages/book-store/book-store.module';
import { TestimonialsModule } from './Pages/testimonials/testimonials.module';
import { EventsModule } from './Pages/events/events.module';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ChannelModule } from './Pages/channel/channel.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HomeModule,
    BrowserModule,
    EventsModule,
    AboutUsModule,
    ChannelModule,
    ContactUsModule,
    BookStoreModule,
    HttpClientModule,
    TestimonialsModule,
    KamykaSharesModule,
    KamykaSharesModule,
    YouTubePlayerModule,
    HttpClientJsonpModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
