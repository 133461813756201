import { Component, OnInit } from '@angular/core';
import { ItemCart } from 'src/app/kamyka-shares/KamykaInterfaces';

@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss'],
})
export class BookListComponent implements OnInit {
  books: ItemCart[] = [
    {
      img: '../../../../assets/img/wow.jpg',
      title: 'W.O.W',
      description: '',
    },
    {
      img: '../../../../../../assets/img/cdyouth.jpg',
      title: 'voices of youth',
      description: '',
    },
    {
      img: '../../../../../../assets/img/dreamchronicle.jpg',
      title: 'dream chronicles',
      description: '',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
