import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem } from '../../../../kamyka-shares/components/footer/footer.component';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit {
  contactInfos: MenuItem[] = [
    {
      icon: {
        name: 'mail-bulk',
        type: '',
      },
      label: 'email me',
      friendly: 'connect@lifecoachk.com',
    },
    {
      icon: {
        name: 'phone',
        type: '',
      },
      label: 'call me',
      friendly: '+1 (410) 417 - 7520',
    },
  ];

  socialNetworks: MenuItem[] = [
    {
      url:
        'https://www.youtube.com/channel/UCCvZvzn8sT9HNr-Uk_D_faw?view_as=subscriber',
      icon: {
        name: 'youtube',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://www.linkedin.com/in/life-coach-k-b44b47120/',
      icon: {
        name: 'linkedin-in',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://www.facebook.com/lifecoachk/',
      icon: {
        name: 'facebook-f',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://twitter.com/4LifeCoachK',
      icon: {
        name: 'twitter',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://www.instagram.com/transformationalcoachk/',
      icon: {
        name: 'instagram',
        type: 'fab',
      },
      friendly: '',
    },
  ];

  buttonTxt = 'send message';
  acknowledgment = 'sending...';
  acknowledgmentTtile = 'Email Confirmation';
  message = 'Your message is being send to coach K.';

  contactForm: FormGroup;
  constructor(private _fb: FormBuilder, private _http: HttpClient) {}

  ngOnInit() {
    this.contactForm = this._fb.group({
      visitorName: ['', [Validators.minLength(1), Validators.required]],
      visitorPhoneNumber: ['', [Validators.minLength(10), Validators.required]],
      visitorEmail: ['', [Validators.email, Validators.required]],
      visitorMessageSubject: ['', [Validators.required]],
      visitorEmailSource: ['Our Website', [Validators.required]],
      visitorMessage: ['', [Validators.minLength(1), Validators.required]],
    });
  }

  submit() {
    if (this.contactForm.valid) {
      this.buttonTxt = 'sending ...';
      this.acknowledgmentTtile = 'Email Confirmation Pending ...';
      const user = {
        name: this.contactForm.value.visitorName,
        phone_number: this.contactForm.value.visitorPhoneNumber,
        sender_email: this.contactForm.value.visitorEmail,
        subject: this.contactForm.value.visitorMessageSubject,
        source: this.contactForm.value.visitorEmailSource,
        content: this.contactForm.value.visitorMessage,
      };
      this._http
        .post(
          `${environment.emailService.protocol}://${environment.emailService.url}/${environment.emailService.param}`,
          user
        )
        .subscribe(
          (data) => {
            const res: any = data;
            // console.log('my email is successfully working');
          },
          (err) => {
            this.buttonTxt = 'send';
          },
          () => {
            this.buttonTxt = 'send';
            this.acknowledgmentTtile = 'Email send';
            this.message = 'Your email has been send...';
            this.acknowledgment = 'thank you';
            this.contactForm.reset();
          }
        );
    }
  }
}
