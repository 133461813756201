import { Component, OnInit } from '@angular/core';
import { ItemCart } from 'src/app/kamyka-shares/KamykaInterfaces';

@Component({
  selector: 'app-testimonial-list',
  templateUrl: './testimonial-list.component.html',
  styleUrls: ['./testimonial-list.component.scss'],
})
export class TestimonialListComponent implements OnInit {
  testimonials: ItemCart[] = [
    {
      img: '../../../../assets/testimonials/alston.jpeg',
      title: 'Tawana Alston',
      description:
        'Thanks to Ms. Glenn, my staff enjoyed taking the opportunity to reflect on their aspirations and goals through a creative approach with laughter, smiles, and good company.',
    },
    {
      img: '../../../../assets/testimonials/private.jpeg',
      title: 'Sherri Y. Johnson',
      description:
        'KaMyka is an example of authenticity. She genuinely cares for others and guides them along their path with practical and spiritual',
    },
    {
      img: '../../../../assets/testimonials/vanney.jpg',
      title: 'Vanney Deutou',
      description:
        'Coach K is an amazing coach. She is knowledgeable about the content she shares and has the ability to inspire and motivate her audience to greatness. She helps her clients maximize their potential by helping them pull from within. I thoroughly enjoy Ms. Glenn as a coach for my company and team as she helps us soar to new heights.',
    },
    {
      img: '../../../../assets/testimonials/hetlena.jpeg',
      title: 'Hetlena Johnson',
      description:
        'KaMyKa is a genius at helping others realize their potentialand power. I really enjoyed having her coach me to a new level in my live.',
    },
    {
      img: '../../../../assets/testimonials/labron.jpeg',
      title: 'LaRon Martin',
      description:
        'KaMyka was a phenomenal presenter. She was knowledgeable about her topic, engaging and inspiring. I thoroughly enjoyed her workshop.',
    },
    {
      img: '../../../../assets/testimonials/candice.jpeg',
      title: 'Candice Hodge',
      description:
        'Dream Chronicles was really inspirational because it reminded me to keep dreaming and to know that dreams are okay.' +
        'Everybody who has been successful in life started off with a dream, a thought, and imagination somewhere. ' +
        'So the book sparked a fire underneath me to keep on dreaming and to really write my vision and make it plain. ' +
        'The book, Dream Chronicles even though it is a children book it really did inspire me as an adult, that I can do ' +
        'anything I set my mind to and just to know that there were so many other people that came before me who had various' +
        'obstacles that were greater and still accomplished so much more.Your book reminded me that our history is not just ' +
        'slavery, our history has black excellence, black magic, black glory, black kings and queens, educators, and scientists' +
        ' You Name It! Your book is an excellent book for children especially to start them off early to dream and to let them ' +
        'know that you can do anything you put your mind to along with hard work, dedication, and consistency.A lot of the people' +
        ' that are mentioned in your book like Oprah, Dr.Martin Luther King, Steve Jobs....a lot of them had failures but they ' +
        "didn't allow their failure to define them.They allowed them to fuel who they are.So that's a " +
        'good reminder for us as adults too....',
    },
    {
      img: '../../../../assets/testimonials/rachel.png',
      title: 'Rachel Thompson',
      description:
        'I’ve had the pleasure to watch Ms. Glenn serve as a mentor presenter for students at my school.' +
        'She has also taught a vision board course for students, where they were engaged, excited, and left' +
        'with a tangible board with their visions displayed I am especially impressed by KaMyka and I highly' +
        'recommend her without reservation',
    },
    {
      img: '../../../../assets/testimonials/shemika.jpeg',
      title: 'Shemia Anderson',
      description:
        'She did some enlightening group exercises to demonstrate how with focus and intention we can move negative energy and ' +
        'obstacles to keep us from strolling down the wrong paths, and then they settled into',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
