<div class="about-container pt-5">
  <section class="w-100 text-center title-section">
    <h1 class="m-0">learn <span class="text-primary">more</span></h1>
    <span class="title-bg">about us</span>
  </section>
  <section class="w-100 pt-5 text-center approach-container bg-light">
    <h1
      class="m-0 pt-5 text-primary normal-big font-weight-bold small-line-height"
    >
      our approach
    </h1>
    <span class="normal-big light-text">to life coaching</span>
    <p class="w-50 mx-auto res-full">
      Coaches have the ability to view things from a far in what some call
      helicopter vision and shed new light on difficult situations. Often they
      can act as a sounding board through tough decisions, help sharpen skills,
      and motivate.
    </p>
    <div class="w-100 p-5 d-flex flex-wrap res-list-wrap">
      <div class="res-approach pb-5" *ngFor="let approach of approaches">
        <mdb-icon
          class="text-primary icon-size"
          fas
          icon="{{ approach.icon.name }}"
        ></mdb-icon>
        <h4 class="text-black w-50 mx-auto pt-3 font-weight-bold">
          {{ approach.friendly }}
        </h4>
        <div class="mx-auto divider mb-3 mt-3"></div>
        <div class="text-reg-size w-50 mx-auto">{{ approach.label }}</div>
      </div>
    </div>
    <div class="w-50 mx-auto border"></div>
    <button class="m-5" type="button" mdbBtn color="primary" mdbWavesEffect>
      view all our classes
    </button>
  </section>
  <section
    class="h-75 p-5 bg-primary text-center res-dedicate position-relative"
  >
    <h1 class="m-0 text-white light-text normal-big small-line-height pt-5">
      Dedicated to your
    </h1>
    <span class="normal-big font-weight-bold">life improvement</span>
    <p class="w-50 mx-auto text-white res-full-width">
      Working one-on-one with a certified Life Coach can help you identify and
      attain your biggest goals. Our private coaching clients experience
      significant breakthroughs that lead them to challenge their thinking,
      resulting in remarkable changes in their careers, relationships and
      quality of life.
    </p>
    <div class="w-100 p-5 d-flex flex-wrap no-pad justify-content-center">
      <div class="m-3 res-improve" *ngFor="let menu of inprovements">
        <div class="icon-wrap mx-auto text-white m-1" [routerLink]="menu.url">
          <mdb-icon
            class="icon-size"
            fas
            icon="{{ menu.icon.name }}"
          ></mdb-icon>
        </div>
        <label class="w-100 pt-3 font-weight-bold text-white">{{
          menu.friendly
        }}</label>
      </div>
    </div>
  </section>
  <section class="w-100">
    <div class="w-100 p-4 text-center text-white contact-card bg-secondary">
      <div>
        <div class="light-text ad-title">
          Newsletter: Discover. Apply. Choose
        </div>
        <p class="light-text w-75 mx-auto">
          Our newsletter will keep you up to date with tips and guide you along
          life’s journey.
        </p>
        <div class="w-100">
          <input
            class="input-frame border-0"
            name="subcribe"
            placeholder="Enter your email address"
          />
          <button type="button" mdbBtn color="dark" mdbWavesEffect>
            subscribe
          </button>
        </div>
      </div>
    </div>
  </section>
  <section class="w-100 p-5 bg-white res-book">
    <div class="book-container w-75 mx-auto">
      <div
        class="m-0 pt-5 pb-6 text-primary title-alt font-weight-bold small-line-height"
      >
        Coaching Tools and Products
      </div>
      <div class="w-100 d-flex pt-5 flex-wrap">
        <div
          class="w-25 book-card mx-auto"
          *ngFor="let slide of availableBooks"
        >
          <div
            class="w-100 d-flex flex-wrap justify-content-around text-white text-center"
            *ngFor="let book of slide.menus"
          >
            <div class="book-wrap">
              <img [src]="book.icon.name" />
            </div>
            <h2 class="w-100 text-primary light-text pt-5">
              <mdb-icon class="w-100 text-reg-size" fas icon="tags"></mdb-icon>
              {{ book.friendly | uppercase }}
            </h2>
            <div class="divider mt-5 w-50 mx-auto border"></div>
            <div class="">
              <button
                class="m-5"
                type="button"
                mdbBtn
                color="primary"
                mdbWavesEffect
              >
                purcharse book
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="divider mt-5 w-50 mx-auto border"></div>
      <div class="w-100 text-center">
        <button
          class="m-5 mx-auto"
          type="button"
          mdbBtn
          color="primary"
          mdbWavesEffect
        >
          visit our store
        </button>
      </div>
    </div>
  </section>
  <section class="visit-container bg-primary p-5 w-100 no-pad">
    <div class="w-75 mx-auto d-flex pt-5 flex-wrap">
      <div class="pt-5 w-50 res-content">
        <div class="text-white title-alt light-text">
          Visit us <span class="black font-weight-bold">anytime</span>
        </div>
        <p class="text-white">
          Life can be challenging and there may not be an answer for every issue
          that you face. Be encouraged and know we are here to ease the process.
        </p>
      </div>
      <div
        class="w-50 d-flex flex-wrap pl-5 res-content no-pad contact-info res-full"
      >
        <div
          class="w-25 pb-5 text-center res-half"
          *ngFor="let contact of contactInfos"
        >
          <div class="icon-contact mx-auto text-white m-1">
            <mdb-icon
              class="icon-size"
              fas
              icon="{{ contact.icon.name }}"
            ></mdb-icon>
          </div>
          <h4
            class="text-white contact-detail w-50 mx-auto pt-3 font-weight-bold"
          >
            {{ contact.friendly }}
          </h4>
          <div class="mx-auto border-white divider mb-3 mt-3"></div>
          <div class="text-reg-size text-white">{{ contact.label }}</div>
        </div>
      </div>
    </div>
  </section>
</div>
