import { Component, OnInit, Input } from '@angular/core';
import { ItemCart } from '../../KamykaInterfaces';

@Component({
  selector: 'app-item-cart',
  templateUrl: './item-cart.component.html',
  styleUrls: ['./item-cart.component.scss'],
})
export class ItemCartComponent implements OnInit {
  @Input() card: ItemCart = null;

  constructor() {}

  ngOnInit(): void {
    console.log(this.card);
  }
}
