import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactUsRoutingModule } from './contact-us-routing.module';
import { ContactPageComponent } from './components/contact-page/contact-page.component';
import { KamykaMaterialsModule } from 'src/app/kamyka-materials/kamyka-materials.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ContactPageComponent],
  imports: [
    CommonModule,
    KamykaMaterialsModule,
    FormsModule,
    ContactUsRoutingModule,
  ],
})
export class ContactUsModule {}
