import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookStoreRoutingModule } from './book-store-routing.module';
import { BookListComponent } from './components/book-list/book-list.component';
import { KamykaMaterialsModule } from 'src/app/kamyka-materials/kamyka-materials.module';
import { KamykaSharesModule } from 'src/app/kamyka-shares/kamyka-shares.module';

@NgModule({
  declarations: [BookListComponent],
  imports: [
    CommonModule,
    KamykaSharesModule,
    KamykaMaterialsModule,
    BookStoreRoutingModule,
  ],
})
export class BookStoreModule {}
