import { Component } from '@angular/core';
import { MenuItem } from './kamyka-shares/components/footer/footer.component';
import { Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'kamykaPorfolio';
  active: string = 'home';
  headersMenus: MenuItem[] = [
    {
      icon: {
        name: 'home',
        type: 'fas',
      },
      friendly: '',
      url: 'home',
    },
    {
      icon: {
        name: 'user',
        type: 'fas',
      },
      friendly: '',
      url: 'about-us',
    },
    {
      icon: {
        name: 'headset',
        type: 'fas',
      },
      friendly: '',
      url: 'contact-k',
    },
    {
      icon: {
        name: 'book-open',
        type: 'fas',
      },
      friendly: '',
      url: 'book-store',
    },
    {
      icon: {
        name: 'comment-dots',
        type: 'fas',
      },
      friendly: '',
      url: 'testimonials',
    },
    {
      icon: {
        name: 'calendar-day',
        type: 'fas',
      },
      friendly: '',
      url: 'coaching-events',
    },
    {
      icon: {
        name: 'blog',
        type: 'fas',
      },
      friendly: '',
      url: 'kameka-tv',
    },
  ];

  constructor(private router: Router) {
    router.events.subscribe((activedUrl: RouterEvent) => {
      if (activedUrl.url) {
        this.coachingNavigation(activedUrl.url);
      }
    });
  }

  ngOnInit() {}

  coachingNavigation(destination: string) {
    if (destination.startsWith('/')) {
      return (this.active = destination.split('/').join(''));
    } else {
      return (this.active = destination);
    }
  }
}
