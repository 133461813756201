<section class="w-100 d-flex flex-wrap item-container p-5">
  <div
    class="image"
    [ngStyle]="{ 'background-image': 'url(' + card.img + ')' }"
  ></div>
  <div class="w-100 pt-5 pb-5">
    <div *ngIf="card.tag">{{ card.tag }}</div>
    <div class="default-signature icon-size">{{ card.title }}</div>
    <div *ngIf="card.description" class="card-details">
      {{ card.description }}
    </div>
  </div>
</section>
