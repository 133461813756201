import { Component, OnInit } from '@angular/core';

interface EventRegistration {
  url: string;
  label: string;
}
export interface CoachingSection {
  title: string;
  date: string;
  time: string;
  free?: string;
  deadline: string;
  benefits?: string[];
  descriptions: string[];
  price?: number | string;
  registration: EventRegistration;
}
export interface CoachingEvent {
  image: string;
  descriptions: string[];
  sections: CoachingSection[];
}
@Component({
  selector: 'app-event-home',
  templateUrl: './event-home.component.html',
  styleUrls: ['./event-home.component.scss'],
})
export class EventHomeComponent implements OnInit {
  coachingEvents: CoachingEvent[] = [
    {
      image: 'assets/img/events/event.jpg',
      descriptions: [
        'A journey of self- discovery to activate the passion within in order to blaze into your destiny.',
      ],
      sections: [
        {
          date: 'Friday, Oct 14, 2022',
          time: '7pm est',
          price: 65,
          title: 'activate',
          descriptions: [
            'A live transformational session with Coach K to help you move into your destiny.',
          ],
          deadline: 'registration closes Sept 30, 2020',
          benefits: ['Both conference days', 'swag bag'],
          registration: {
            label: 'activate registration',
            url: '',
          },
        },
        {
          price: 0,
          free: 'free',
          title: 'blaze',
          descriptions: [
            'An interactive discussion that helps you shift from being stuck and liberates you into our purpose, while igniting your destiny.',
          ],
          time: '10am est',
          date: 'Saturday, October 17, 2020',
          deadline: 'registration is open until the day of the event',
          registration: {
            label: 'blaze registration',
            url: '  ',
          },
        },
      ],
    },
  ];
  constructor() { }

  ngOnInit(): void { }
}
