import { Component, OnInit } from '@angular/core';
import {
  MenuItem,
  Sectionized,
} from 'src/app/kamyka-shares/components/footer/footer.component';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
})
export class AboutPageComponent implements OnInit {
  approaches: MenuItem[] = [
    {
      icon: {
        name: 'fist-raised',
        type: 'fas',
      },
      friendly: 'Strengthen your emotional bond',
      label:
        'No matter what kind of relationship, a solid relationship is built on trust, emotional intimacy, genuine support, and respect.',
    },
    {
      icon: { name: 'comments', type: 'fas' },
      friendly: 'Improve your communication',
      label: 'Listen actively, communicate and convey feeling effectively.',
    },
    {
      icon: { name: 'user-friends', type: '' },
      friendly: 'Passion',
      label: 'Discover who you are so you can ignite the passion within.',
    },
    {
      icon: { name: 'heart', type: '' },
      friendly: 'Embrace change',
      label: 'Release the past, live in the present, and plan for the future',
    },
    {
      icon: { name: 'user-check', type: '' },
      friendly: 'Feel complete, inside and outside',
      label: 'Trust the process and believe in yourself.',
    },
    {
      icon: { name: 'balance-scale', type: '' },
      friendly: 'Know your worth',
      label: 'You are enough.',
    },
  ];

  inprovements: MenuItem[] = [
    {
      icon: { name: 'brain', type: '' },
      friendly: 'Identify your goals',
      url: 'home',
    },
    {
      icon: { name: 'comments', type: '' },
      friendly: 'Find the answers',
    },
    {
      icon: { name: 'balance-scale', type: '' },
      friendly: 'Find the Balance',
    },
    {
      icon: { name: 'trophy', type: '' },
      friendly: 'Achieve success',
    },
  ];

  availableBooks: Sectionized[] = [
    {
      title: '',
      menus: [
        {
          icon: { name: '../../../../../../assets/img/wow.jpg', type: '' },
          url: '',
          friendly: 'w.o.w',
        },
      ],
    },
    {
      title: '',
      menus: [
        {
          icon: { name: '../../../../../../assets/img/cdyouth.jpg', type: '' },
          url: '',
          friendly: 'voices of youth',
        },
      ],
    },
    {
      title: '',
      menus: [
        {
          icon: {
            name: '../../../../../../assets/img/dreamchronicle.jpg',
            type: '',
          },
          url: '',
          friendly: 'dream chronicles',
        },
      ],
    },
  ];

  contactInfos: MenuItem[] = [
    {
      icon: {
        name: 'phone',
        type: '',
      },
      friendly: 'Call us',
      label: '+1 (410) 417 - 7520',
    },
    {
      icon: {
        name: 'paper-plane',
        type: '',
      },
      friendly: 'Email',
      label: 'connect@lifecoachk.com',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
