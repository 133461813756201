<div class="w-100 text-white pt-5 no-pad">
  <div
    class="w-75 mx-auto res-footer d-flex justify-content-between align-items-center pt-5"
  >
    <h1 class="w-100 font-weight-bold text-primary logo">
      Transformational <span class="text-white">Coach</span> K
    </h1>
    <div class="d-flex flex-wrap">
      <p class="w-100 text-white">Visit us on social network:</p>
      <div class="d-flex w-100 justify-content-center">
        <a
          target="_blank"
          href="{{ socialNetwork.url }}"
          class="ml-4 big-fonts text-primary"
          *ngFor="let socialNetwork of socialNetworks"
        >
          <mdb-icon fab icon="{{ socialNetwork.icon.name }}"></mdb-icon>
        </a>
      </div>
    </div>
  </div>
  <div class="w-75 pt-4 mx-auto h-separator"></div>
  <div class="w-75 res-footer mx-auto pb-5 pt-4">
    <div class="w-100 d-flex res-foot-wrap justify-content-between">
      <div
        class="w-25 res-foot-sec p-2"
        *ngFor="let footerSection of footerSections"
      >
        <div class="section-title light-weight">
          {{ footerSection.title | titlecase }}
        </div>
        <div class="pt-2">
          <div
            class="pt-3 footer-link"
            *ngFor="let menuItem of footerSection.menus"
          >
            <span *ngIf="menuItem.icon">
              <mdb-icon fas icon="{{ menuItem.icon.name }}"></mdb-icon>
            </span>
            <label class="w-100 m-0 text-white-50" *ngIf="menuItem.date">{{
              menuItem.date | uppercase
            }}</label>
            {{ menuItem.friendly | titlecase }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="w-75 res-footer mx-auto d-flex justify-content-between bg-primary p-4"
  >
    <p class="m-0">
      Transformational Coach K. <mdb-icon fas icon="copyright"></mdb-icon>
      {{ currentYear }}
      made by Hintekk LLC. All Right Reserved.
    </p>
    <div class="d-flex flex-wrap">
      <div class="pl-2" *ngFor="let menu of menus">
        <a class="text-white k-link" href="{{ menu.url }}" target="_blank">{{
          menu.friendly
        }}</a>
      </div>
    </div>
  </div>
</div>
