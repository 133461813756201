import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestimonialsRoutingModule } from './testimonials-routing.module';
import { TestimonialListComponent } from './components/testimonial-list/testimonial-list.component';
import { KamykaSharesModule } from 'src/app/kamyka-shares/kamyka-shares.module';
import { KamykaMaterialsModule } from 'src/app/kamyka-materials/kamyka-materials.module';

@NgModule({
  declarations: [TestimonialListComponent],
  imports: [
    CommonModule,
    KamykaSharesModule,
    KamykaMaterialsModule,
    TestimonialsRoutingModule,
  ],
})
export class TestimonialsModule {}
