<div class="w-100 testimonial-container position-relative">
  <section class="w-100 text-center title-section pt-5">
    <h1 class="m-0 pt-5">
      word on<span class="text-primary"> the street</span>
    </h1>
    <span class="title-bg">Testimonials</span>
  </section>
  <section class="w-100 p-5 bg-white d-flex flex-wrap res-list">
    <div
      class="testimony-wrap mx-auto"
      *ngFor="let testimonial of testimonials"
    >
      <app-item-cart [card]="testimonial"></app-item-cart>
    </div>
  </section>
  <section
    class="w-50 res-quote p-5 h-20 position-absolute bg-primary letter text-white"
  >
    <q>
      I am a coach, educator, author, and consultant. I advise on how to live
      out your purpose through life's transitions. For more than 15 years, I
      have empowered others to manifest their dreams
    </q>
    <span class="w-100 d-block secondary-signature icon-size text-right"
      >KaMyka Glenn</span
    >
  </section>
</div>
