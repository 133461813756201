import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutUsRoutingModule } from './about-us-routing.module';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { KamykaMaterialsModule } from 'src/app/kamyka-materials/kamyka-materials.module';
import { KamykaSharesModule } from 'src/app/kamyka-shares/kamyka-shares.module';

@NgModule({
  declarations: [AboutPageComponent],
  imports: [
    CommonModule,
    KamykaMaterialsModule,
    KamykaSharesModule,
    AboutUsRoutingModule,
  ],
})
export class AboutUsModule {}
