<div class="channel-container pt-5">
  <section class="w-100 text-center title-section">
    <h1 class="m-0">take <span class="text-primary">action</span></h1>
    <span class="title-bg">coach k tv</span>
  </section>
  <section class="w-100 bg-white">
    <div class="w-75 mx-auto d-flex flex-wrap res-youtube">
      <div class="m-2 video-wrap">
        <youtube-player
          videoId="YpXS3XbOkAc"
          suggestedQuality="highres"
          [width]="'inherit'"
          [height]="'inherit'"
          [showBeforeIframeApiLoads]="true"
        >
        </youtube-player>
      </div>
      <div class="m-2">
        <youtube-player
          videoId="__outwVLkOw"
          suggestedQuality="highres"
          [width]="'inherit'"
          [height]="'inherit'"
          [showBeforeIframeApiLoads]="true"
        >
        </youtube-player>
      </div>
      <div class="m-2">
        <youtube-player
          videoId="MzU3X78NIlc"
          suggestedQuality="highres"
          [width]="'inherit'"
          [height]="'inherit'"
          [showBeforeIframeApiLoads]="true"
        >
        </youtube-player>
      </div>

      <div class="m-2">
        <youtube-player
          videoId="TfDtytuyLNs"
          suggestedQuality="highres"
          [width]="'inherit'"
          [height]="'inherit'"
          [showBeforeIframeApiLoads]="true"
        >
        </youtube-player>
      </div>
      <div class="m-2">
        <youtube-player
          videoId="uCJaEamxftI"
          suggestedQuality="highres"
          [width]="'inherit'"
          [height]="'inherit'"
          [showBeforeIframeApiLoads]="true"
        >
        </youtube-player>
      </div>
    </div>
  </section>
</div>
