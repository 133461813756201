import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChannelRoutingModule } from './channel-routing.module';
import { ChannelComponent } from './components/channel/channel.component';
import { KamykaMaterialsModule } from 'src/app/kamyka-materials/kamyka-materials.module';
import { KamykaSharesModule } from 'src/app/kamyka-shares/kamyka-shares.module';

@NgModule({
  declarations: [ChannelComponent],
  imports: [
    CommonModule,
    KamykaSharesModule,
    KamykaMaterialsModule,
    ChannelRoutingModule,
  ],
})
export class ChannelModule {}
