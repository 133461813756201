import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CoachingIcon } from '../../KamykaInterfaces';

export interface MenuItem {
  icon?: CoachingIcon;
  friendly: string;
  url?: string;
  label?: string;
}

export interface Sectionized {
  title: string;
  menus: MenuItem[];
}

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear = moment().format('YYYY');
  socialNetworks: MenuItem[] = [
    {
      url:
        'https://www.youtube.com/channel/UCCvZvzn8sT9HNr-Uk_D_faw?view_as=subscriber',
      icon: {
        name: 'youtube',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://www.linkedin.com/in/life-coach-k-b44b47120/',
      icon: {
        name: 'linkedin-in',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://www.facebook.com/lifecoachk/',
      icon: {
        name: 'facebook-f',
        type: 'fab',
      },
      friendly: '',
    },
    {
      url: 'https://twitter.com/4LifeCoachK',
      icon: {
        name: 'twitter',
        type: 'fas',
      },
      friendly: '',
    },
    {
      url: 'https://www.instagram.com/transformationalcoachk/',
      icon: {
        name: 'instagram',
        type: 'fab',
      },
      friendly: '',
    },
  ];

  footerSections: Sectionized[] = [
    {
      title: 'contact us anytime',
      menus: [
        {
          icon: {
            name: 'mobile',
            type: 'fas',
          },
          friendly: '+1 (410) 417 - 7520',
        },
        {
          icon: {
            name: 'envelope',
            type: 'fas',
          },
          friendly: 'connect@lifecoachk.com',
        },
      ],
    },
    {
      title: 'more about T. Coach K.',
      menus: [
        {
          friendly: 'about us',
          url: '',
        },
        {
          friendly: 'management team',
          url: '',
        },
        {
          friendly: 'contact',
          url: '',
        },
        {
          friendly: 'location',
          url: '',
        },
        {
          friendly: 'pricing',
          url: '',
        },
      ],
    },
    {
      title: 'latest news',
      menus: [
        {
          label: 'january 12, 2020',
          friendly: 'thoughts on procrastination',
        },
        {
          label: 'january 22, 2020',
          friendly: 'People who made inspiring life changes',
        },
        {
          label: 'February 22, 2020',
          friendly: 'The incredible power of small changes',
        },
      ],
    },
    {
      title: 'latest courses',
      menus: [
        {
          friendly: 'What is life coaching',
          url: '',
        },
        {
          friendly: 'life coaching and your family',
          url: '',
        },
        {
          friendly: 'Find your life purpose',
          url: '',
        },
        {
          friendly: 'Tap into endless motivation',
          url: '',
        },
        {
          friendly: 'Coaching focuses on your future',
          url: '',
        },
      ],
    },
  ];

  menus: MenuItem[] = [
    {
      friendly: 'About us',
      url: 'about-us',
    },
    {
      friendly: 'Management Team',
      url: '',
    },
    {
      friendly: 'Contact',
      url: 'https://transformwithcoachk.as.me/',
    },
    {
      friendly: 'Location',
      url: '',
    },
    {
      friendly: 'Pricing',
      url: '',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
